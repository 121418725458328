import { Link } from 'react-router-dom';
import cn from 'classnames';
import { CTA_PRIMARY, PATHS } from '../../utils/constants';
import Image from '../../components/Image/Image';
import Video from '../../components/Video/Video';

/**
 * Home page component
 */
const HomePage = () => {
  return (
    <div className='text-lg'>
      <h2 className='text-center'>Quick Start</h2>

      <Video
        src='https://www.youtube.com/embed/u31qwQUeGuM'
        classNames='mb-8'
      />

      <section>
        <h2 className='text-center'>The Problem</h2>
        <p>Managing your money is hard:</p>
        <ul>
          <li className='flex'>
            <span className='mr-2'>💸</span>
            <span>Expenses pile up unexpectedly.</span>
          </li>
          <li className='flex'>
            <span className='mr-2'>🤯</span>
            <span>Keeping track of accounts and budgets is overwhelming.</span>
          </li>
          <li className='flex'>
            <span className='mr-2'>🧩</span>
            <span>
              Saving for goals feels impossible without clear planning.
            </span>
          </li>
          <li className='flex'>
            <span className='mr-2'>⏳</span>
            <span>
              Budgeting tools often feel too complicated or too expensive.
            </span>
          </li>
        </ul>
      </section>

      <section>
        <h2 className='text-center'>The Solution</h2>
        <p>
          Meet <span className='callout'>Brainy Budget</span>, the app that
          makes budgeting smarter and stress-free.
        </p>
        <p>
          With simple inputs, virtual accounts, and AI-powered insights, you can
          manage your finances <em>your</em> way.
        </p>
      </section>

      <Image src='/images/home/the-solution.jpg' alt='' classNames='mb-8' />

      <CTA classNames='mb-8' />

      <section>
        <h2 className='text-center'>Key Features</h2>
        <ul>
          <li className='flex'>
            <span className='mr-2'>🔒</span>
            <span>
              <span className='callout'>Privacy</span>: No need to connect your
              accounts&mdash;enter balances and budgets manually and manage them
              all in one place.
            </span>
          </li>
          <li className='flex'>
            <span className='mr-2'>🌐</span>
            <span>
              <span className='callout'>Virtual Accounts</span>: Segment your
              checking and savings accounts to increase awareness of where your
              money is going.
            </span>
          </li>
          <li className='flex'>
            <span className='mr-2'>🧠</span>
            <span>
              <span className='callout'>Ask AI</span>: Get instant answers to
              financial questions like{' '}
              <i>"How much did I budget for dining this month?"</i> or{' '}
              <i>"How can I get on track for my vacation goal?"</i>
            </span>
          </li>
          <li className='flex'>
            <span className='mr-2'>🤖</span>
            <span>
              <span className='callout'>Personalized Insights</span>: Let our AI
              analyze your budgets and suggest smarter saving and spending
              strategies.
            </span>
          </li>
          <li className='flex'>
            <span className='mr-2'>📊</span>
            <span>
              <span className='callout'>Trends & Tips</span>: Access valuable
              insights based on anonymized data from users like you.
            </span>
          </li>
          <li className='flex'>
            <span className='mr-2'>🔀</span>
            <span>
              <span className='callout'>Profile Management</span>: Create
              separate profiles for Personal, Family, Business, or anything you
              need&mdash;and transfer money between them.
            </span>
          </li>
        </ul>
      </section>

      <Image src='/images/home/key-features.jpg' alt='' classNames='mb-8' />

      <section>
        <h2 className='text-center'>How It Works</h2>
        <ol className='ml-8'>
          <li className='list-decimal'>
            <span className='callout'>Create Your Accounts</span>: Enter your
            balances and customize your virtual accounts.
          </li>
          <li className='list-decimal'>
            <span className='callout'>Set Your Budgets</span>: Add budgets to
            your virtual accounts&mdash;without connecting to your banks.
          </li>
          <li className='list-decimal'>
            <span className='callout'>Switch Between Profiles</span>: Manage
            personal, family, and business budgets all in one app.
          </li>
          <li className='list-decimal'>
            <span className='callout'>Ask, Learn, & Save</span>: Use our AI to
            optimize your spending and saving.
          </li>
        </ol>
      </section>

      <Image src='/images/home/how-it-works.jpg' alt='' classNames='mb-8' />

      <section>
        <h2 className='text-center'>Why Choose Brainy Budget?</h2>
        <ul>
          <li className='flex'>
            <span className='mr-2'>💰</span>
            <span>
              <span className='callout'>Affordable</span>: Try it for free, and
              if you love it, it's{' '}
              <span className='font-bold text-green-500'>
                only $3.99 per month
              </span>
              .
            </span>
          </li>
          <li className='flex'>
            <span className='mr-2'>🔒</span>
            <span>
              <span className='callout'>Your Privacy Matters</span>: No bank
              connections&mdash;just smart budgeting tools.
            </span>
          </li>
          <li className='flex'>
            <span className='mr-2'>🔧</span>
            <span>
              <span className='callout'>Built for Real Life</span>: Lock down
              your expenses across credit cards and banks.
            </span>
          </li>
          <li className='flex'>
            <span className='mr-2'>🧵</span>
            <span>
              <span className='callout'>Tailored to You</span>: Fully
              customizable without overcomplicating the process.
            </span>
          </li>
        </ul>
      </section>

      <CTA classNames='mb-8' />

      <section>
        <h2>What People Say</h2>
        <div className='grid gap-4 xs:gap-8 sm:grid-cols-2'>
          <blockquote>
            <span className='callout'>
              I finally feel in control of my finances
            </span>
            . Creating virtual accounts for my bills, groceries, and savings has
            made managing my money so much easier!
            <footer>~ Aisha Khan</footer>
          </blockquote>
          <blockquote>
            <span className='callout'>
              The AI feature is like having a financial advisor in my pocket
            </span>
            . I love being able to ask questions about my budget and get clear
            answers instantly!
            <footer>~ Michael Brown</footer>
          </blockquote>
          <blockquote>
            We saved over $500 in just two months using Brainy Budget's
            recommendations.{' '}
            <span className='callout'>The insights are spot on!</span>
            <footer>~ Laura Martinez</footer>
          </blockquote>
          <blockquote>
            With a side-gig, tracking business income and expenses was a
            nightmare&mdash;until I found Brainy Budget. The ability to{' '}
            <span className='callout'>
              switch between personal and business profiles
            </span>{' '}
            is a lifesaver!
            <footer>~ David Wilson</footer>
          </blockquote>
          <blockquote>
            I've always been a fan of the envelope budgeting method, and{' '}
            <span className='callout'>
              this app takes it to the next level with AI
            </span>
            . The insights and trend analysis are invaluable!{' '}
            <footer>~ Sarah Davis</footer>
          </blockquote>
          <blockquote>
            <span className='callout'>
              It's affordable, effective, and respects my privacy
            </span>
            . No bank connections, just smart budgeting tools that work.
            <footer>~ Carlos Garcia</footer>
          </blockquote>
        </div>
      </section>

      <Image src='/images/home/what-people-say.jpg' alt='' classNames='mb-8' />

      <CTA />
    </div>
  );
};

/**
 * Call to action button
 */
const CTA = ({ classNames }: { classNames?: string }) => (
  <Link to={PATHS.AUTH} className={cn(CTA_PRIMARY, classNames)}>
    Try It Free Today
  </Link>
);

export default HomePage;

import { createSlice } from '@reduxjs/toolkit';
import { checkHealth } from '../health/health.slice';
import { RootState } from '../store';
import { RejectedAction } from '../state.types';
import { getUserIdentity, logout, verifySignup } from '../auth/auth.slice';
import { getUser } from '../user/user.slice';
import { AuthErrorRes } from '../auth/auth.types';

interface ErrorState {
  message: string | null;
}

const initialState: ErrorState = {
  message: null,
};

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addMatcher(
      (action): action is RejectedAction<unknown, AuthErrorRes> =>
        [
          checkHealth.rejected.type,
          verifySignup.rejected.type,
          getUserIdentity.rejected.type,
          getUser.rejected.type,
          logout.rejected.type,
        ].includes(action.type),
      (state, action) => {
        if (action.meta.aborted) return;
        state.message = action.payload.message;
      },
    );
  },
});

/**
 * Reducer
 */
export const errorReducer = errorSlice.reducer;

/**
 * Selectors
 */
export const selectGlobalErrorMessage = (state: RootState) =>
  state.error.message;

import axios, { InternalAxiosRequestConfig } from 'axios';
import { store, RootState } from '../state/store';

/**
 * Renews the user's auth token
 */
const renewUserAuthTokenAndOrSetAuthHeader = async (
  config: InternalAxiosRequestConfig,
) => {
  // get `userAuthToken` from store
  const {
    auth: {
      data: { userAuthToken },
    },
  } = store.getState() as RootState;

  if (!userAuthToken) return config;

  config.headers.Authorization = `Bearer ${userAuthToken.access_token}`;
  return config;
};

export const identityHTTP = axios.create({
  baseURL: 'https://brainybudget.com/.netlify/identity',
});
identityHTTP.interceptors.request.use(renewUserAuthTokenAndOrSetAuthHeader);

export const apiHTTP = axios.create();
apiHTTP.interceptors.request.use(renewUserAuthTokenAndOrSetAuthHeader);

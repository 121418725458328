import { PropsWithChildren, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { verifySignup } from '../../state/auth/auth.slice';
import { HashKey, PATHS } from '../../utils/constants';
import { useAppDispatch } from '../../hooks/redux';

/**
 * SignupVerification component
 */
const SignupVerification = ({ children }: PropsWithChildren) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [hash, setHash] = useState(location.hash);
  const [isSignupVerification, setIsSignupVerification] = useState(
    hash.startsWith(HashKey.ConfirmationToken),
  );

  useEffect(() => {
    if (isSignupVerification) {
      const verifySignupPromise = dispatch(verifySignup(hash.split('=')[1]));
      navigate(PATHS.HOME, { replace: true });

      verifySignupPromise.then(action => {
        if (verifySignup.fulfilled.match(action)) {
          setHash('');
          setIsSignupVerification(false);
        }
      });

      return () => {
        verifySignupPromise.abort();
      };
    }
  }, [isSignupVerification, hash, navigate, dispatch]);

  return isSignupVerification ? null : <>{children}</>;
};

export default SignupVerification;

import cn from 'classnames';

interface ImageProps {
  src: string;
  alt: string;
  classNames?: string;
}

/**
 * Image component
 */
const Image = ({ src, alt, classNames }: ImageProps) => {
  return (
    <img
      loading='lazy'
      src={src}
      alt={alt}
      className={cn(
        'h-60 w-full rounded-2xl bg-neutral-700 object-cover xs:h-72 sm:h-80',
        classNames,
      )}
    />
  );
};

export default Image;

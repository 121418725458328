import cn from 'classnames';
import { Link, useMatch } from 'react-router-dom';
import { PATHS } from '../../utils/constants';

const baseClasses = 'link rounded-full px-4 py-2 w-full text-center';
const activeClass = 'bg-neutral-700';

/**
 * Navigation
 */
const Navigation = () => {
  const isHomePageMatch = useMatch(PATHS.HOME);
  const isAuthPageMatch = useMatch(PATHS.AUTH);
  const isTutorialsPageMatch = useMatch(PATHS.TUTORIALS);
  const isAboutPageMatch = useMatch(PATHS.ABOUT);

  return (
    <>
      <div className='mx-auto mb-4 flex max-w-60 flex-col items-center text-lg xs:max-w-none xs:flex-row xs:justify-around'>
        <Link
          to={PATHS.HOME}
          className={cn(baseClasses, isHomePageMatch && activeClass)}
        >
          Home
        </Link>
        <Link
          to={PATHS.AUTH}
          className={cn(baseClasses, isAuthPageMatch && activeClass)}
        >
          App
        </Link>
        <Link
          to={PATHS.TUTORIALS}
          className={cn(baseClasses, isTutorialsPageMatch && activeClass)}
        >
          Tutorials
        </Link>
        <Link
          to={PATHS.ABOUT}
          className={cn(baseClasses, isAboutPageMatch && activeClass)}
        >
          About
        </Link>
      </div>
      <hr className='mb-8 border-neutral-300' />
    </>
  );
};

export default Navigation;

import { PropsWithChildren, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { renewToken, selectUserAuthToken } from '../../state/auth/auth.slice';

/**
 * TokenRenewal component
 * @description User closes tab and reopens it after token expires
 */
const TokenRenewal = ({ children }: PropsWithChildren) => {
  const dispatch = useAppDispatch();
  const userAuthToken = useAppSelector(selectUserAuthToken);

  useEffect(() => {
    if (!userAuthToken) return;

    if (userAuthToken.expires_at <= Date.now()) {
      const renewTokenPromise = dispatch(
        renewToken(userAuthToken.refresh_token),
      );

      return () => renewTokenPromise.abort();
    }
  }, [userAuthToken, dispatch]);

  if (userAuthToken) {
    return userAuthToken.expires_at <= Date.now() ? null : <>{children}</>;
  }

  return <>{children}</>;
};

export default TokenRenewal;

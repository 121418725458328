interface AuthErrorProps {
  message: string;
}

/**
 * Auth error component
 * @description Used in `PasswordRecovery` and `HomePage` components
 */
const AuthError = ({ message }: AuthErrorProps) => {
  return (
    <p
      role='alert'
      className='mb-6 rounded-lg border-2 border-red-600 bg-red-900 p-2 text-center xs:p-4'
    >
      {message}
    </p>
  );
};
export default AuthError;

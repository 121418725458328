import { RouteObject } from 'react-router-dom';
import App from '../components/App/App';
import RedirectIfAuthed from '../components/RedirectIfAuthed/RedirectIfAuthed';
import RedirectIfNotAuthed from '../components/RedirectIfNotAuthed/RedirectIfNotAuthed';
import Redirect from '../components/Redirect/Redirect';
import { PATHS } from '../utils/constants';
import AccountPage from './App/AccountPage/AccountPage';
import AccountsPage from './App/AccountsPage/AccountsPage';
import BudgetItemPage from './App/BudgetItemPage/BudgetItemPage';
import BudgetPage from './App/BudgetPage/BudgetPage';
import HomePage from './HomePage/HomePage';
import SettingsPage from './App/SettingsPage/SettingsPage';
import TransactionPage from './App/TransactionPage/TransactionPage';
import TransactionSettingsPage from './App/TransactionSettingsPage/TransactionSettingsPage';
import TransactionsPage from './App/TransactionsPage/TransactionsPage';
import AuthPage from './AuthPage/AuthPage';
import TutorialsPage from './TutorialsPage/TutorialsPage';
import AboutPage from './AboutPage/AboutPage';

const routes: RouteObject[] = [
  {
    path: PATHS.HOME,
    element: (
      <RedirectIfAuthed
        NotAuthedComponent={HomePage}
        authedPath={PATHS.TRANSACTIONS}
      />
    ),
  },
  {
    path: PATHS.AUTH,
    element: (
      <RedirectIfAuthed
        NotAuthedComponent={AuthPage}
        authedPath={PATHS.TRANSACTIONS}
      />
    ),
  },
  {
    path: PATHS.TUTORIALS,
    element: (
      <RedirectIfAuthed
        NotAuthedComponent={TutorialsPage}
        authedPath={PATHS.TRANSACTIONS}
      />
    ),
  },
  {
    path: PATHS.ABOUT,
    element: (
      <RedirectIfAuthed
        NotAuthedComponent={AboutPage}
        authedPath={PATHS.TRANSACTIONS}
      />
    ),
  },
  // QUESTION: can this be lazy loaded?
  // TODO: add state to router to redirect to last visited page
  {
    path: PATHS.APP,
    element: (
      <RedirectIfNotAuthed AuthedComponent={App} notAuthedPath={PATHS.HOME} />
    ),
    children: [
      {
        path: 'transactions',
        element: <TransactionsPage />,
        children: [
          {
            path: ':id',
            element: <TransactionPage />,
          },
        ],
      },
      {
        path: 'budget',
        element: <BudgetPage />,
        children: [
          {
            path: ':item',
            element: <BudgetItemPage />,
          },
        ],
      },
      {
        path: 'accounts',
        element: <AccountsPage />,
        children: [
          {
            path: ':account',
            element: <AccountPage />,
          },
        ],
      },
      {
        path: 'settings',
        element: <SettingsPage />,
        children: [
          {
            path: 'transaction',
            element: <TransactionSettingsPage />,
          },
          // TODO: Add more settings pages here
        ],
      },
    ],
  },
  {
    path: '*',
    element: <Redirect to={PATHS.HOME} />,
  },
];

export default routes;

import { PropsWithChildren, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import {
  getUserIdentity,
  selectHasUserAuthToken,
  selectHasUserIdentity,
} from '../../state/auth/auth.slice';
import { getUser, selectHasUser } from '../../state/user/user.slice';

/**
 * UserIdentityAndData component
 */
const UserIdentityAndData = ({ children }: PropsWithChildren) => {
  const dispatch = useAppDispatch();
  const hasUserAuthToken = useAppSelector(selectHasUserAuthToken);
  const hasUserIdentity = useAppSelector(selectHasUserIdentity);
  const hasUser = useAppSelector(selectHasUser);

  useEffect(() => {
    if (!hasUserAuthToken) return;

    const userIdentityPromise = dispatch(getUserIdentity());
    const userPromise = dispatch(getUser());

    return () => {
      userIdentityPromise.abort();
      userPromise.abort();
    };
  }, [dispatch, hasUserAuthToken]);

  if (hasUserAuthToken) {
    return hasUserIdentity && hasUser ? <>{children}</> : null;
  }

  return <>{children}</>;
};
export default UserIdentityAndData;

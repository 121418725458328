import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import AppTabs from '../AppTabs/AppTabs';
import { PATHS } from '../../utils/constants';
import useRenewUserAuthTokenInterval from '../../hooks/useRenewUserAuthTokenInterval';

/**
 * Main app component after authentication
 */
const App = () => {
  const navigate = useNavigate();
  useRenewUserAuthTokenInterval();

  // if navigating to /app, replace with /app/transactions
  useEffect(() => {
    if (window.location.pathname === PATHS.APP) {
      navigate(PATHS.TRANSACTIONS, { replace: true });
    }
  }, [navigate]);

  return (
    <div className='mx-auto flex h-dvh max-w-screen-sm flex-col bg-neutral-800'>
      <Outlet />
      <AppTabs />
    </div>
  );
};

export default App;

import { isAxiosError } from 'axios';
import { ERROR_MSGS } from '../utils/constants';
import { RejectWithValue } from './state.types';

/**
 * Handle error response from API
 */
export const handleError = (
  error: unknown,
  rejectWithValue: RejectWithValue,
) => {
  if (isAxiosError(error) && error.response && error.response.data) {
    const { msg, message, error_description } = error.response.data;

    return rejectWithValue({
      message: msg || message || error_description || ERROR_MSGS.GENERIC,
    });
  }

  return rejectWithValue({
    message: ERROR_MSGS.GENERIC,
  });
};

import { useEffect, useRef, useState } from 'react';
import { useAppSelector } from '../../hooks/redux';
import { selectIsLoading } from '../../state/loading/loading.slice';
import BodyLock from '../BodyLock/BodyLock';
import Logo from '../Logo/Logo';

interface LoaderProps {
  fullScreen?: boolean;
}

/**
 * Loader component
 */
const Loader = ({ fullScreen = false }: LoaderProps) => {
  const isLoading = useAppSelector(selectIsLoading);
  const [hasAnimated, setHasAnimated] = useState(false);
  const maskingDivRef = useRef<HTMLDivElement>(null);

  /**
   * Start opacity transition after loading
   */
  useEffect(() => {
    if (!isLoading) {
      maskingDivRef.current?.classList.add('opacity-0');
      const timeoutId = setTimeout(() => {
        setHasAnimated(true);
      }, 1000);

      return () => clearTimeout(timeoutId);
    }
  }, [isLoading]);

  /**
   * Remove opacity transition after animation
   */
  useEffect(() => {
    if (hasAnimated) {
      maskingDivRef.current?.classList.remove('opacity-0');
    }
  }, [hasAnimated]);

  if (!isLoading && hasAnimated) return null;

  const logo = <Logo role='progressbar' />;

  if (fullScreen) {
    return (
      <div
        ref={maskingDivRef}
        className='fixed inset-0 grid place-content-center bg-neutral-800 transition-opacity duration-1000'
        role='dialog'
        aria-labelledby='logo'
      >
        {logo}
        <BodyLock />
      </div>
    );
  }

  return logo;
};

export default Loader;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { STATE_STATUS } from '../../utils/constants';
import { RootState } from '../store';
import { handleError } from '../state.utils';
import { UserState } from './user.types';
import userAPI from './user.api';

const initialState: UserState = {
  statuses: {
    getUser: STATE_STATUS.INITIAL,
  },
  data: null,
};

/**
 * Thunks
 */
export const getUser = createAsyncThunk(
  'user/getUser',
  async (_, { signal, rejectWithValue }) => {
    try {
      const { data } = await userAPI.getUser(signal);
      return data;
    } catch (error) {
      return handleError(error, rejectWithValue);
    }
  },
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getUser.pending, state => {
        state.statuses.getUser = STATE_STATUS.PENDING;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.data = action.payload;
        state.statuses.getUser = STATE_STATUS.FULFILLED;
      })
      .addCase(getUser.rejected, state => {
        state.statuses.getUser = STATE_STATUS.REJECTED;
      });
  },
});

/**
 * Selectors
 */
export const selectUser = (state: RootState) => state.user.data;
export const selectHasUser = (state: RootState) => !!state.user.data;

/**
 * Reducer
 */
export const userReducer = userSlice.reducer;

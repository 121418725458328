import { PropsWithChildren, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import {
  checkHealth,
  selectCheckHealthStatus,
} from '../../state/health/health.slice';
import { STATE_STATUS } from '../../utils/constants';
import { selectHasUserIdentity } from '../../state/auth/auth.slice';

/**
 * HealthCheck component
 */
const HealthCheck = ({ children }: PropsWithChildren) => {
  const dispatch = useAppDispatch();
  const checkHealthStatus = useAppSelector(selectCheckHealthStatus);
  const hasUserIdentity = useAppSelector(selectHasUserIdentity);

  /**
   * Init on mount
   */
  useEffect(() => {
    // NOTE: on logout, `hasUserIdentity` will be false, triggering the component tree to rerender
    if (!hasUserIdentity) {
      const checkHealthPromise = dispatch(checkHealth());

      return () => {
        checkHealthPromise.abort();
      };
    }
  }, [dispatch, hasUserIdentity]);

  return checkHealthStatus === STATE_STATUS.FULFILLED ? <>{children}</> : null;
};

export default HealthCheck;

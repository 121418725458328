import { FormEvent, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import {
  initiatePasswordRecovery,
  login,
  resetAuthErrorMsg,
  selectAuthErrorMsg,
  selectIsInitiatePasswordRecoveryPending,
  selectIsInitiatePasswordRecoveryRejected,
  selectIsLoginPending,
  selectIsLoginRejected,
  selectIsSignupPending,
  selectIsSignupRejected,
  signup,
} from '../../state/auth/auth.slice';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import AuthError from '../../components/AuthError/AuthError';
import { CTA_PRIMARY, INPUTS } from '../../utils/constants';
import ButtonSpinner from '../../components/ButtonSpinner/ButtonSpinner';

/**
 * Auth page component
 */
const AuthPage = () => {
  const dispatch = useAppDispatch();
  // selectors (pending/rejected)
  const isSignupPending = useAppSelector(selectIsSignupPending);
  const isSignupRejected = useAppSelector(selectIsSignupRejected);
  const isLoginPending = useAppSelector(selectIsLoginPending);
  const isLoginRejected = useAppSelector(selectIsLoginRejected);
  const isInitiatePasswordRecoveryPending = useAppSelector(
    selectIsInitiatePasswordRecoveryPending,
  );
  const isInitiatePasswordRecoveryRejected = useAppSelector(
    selectIsInitiatePasswordRecoveryRejected,
  );
  // selectors (error message)
  const authErrorMsg = useAppSelector(selectAuthErrorMsg);
  // login or signup, or forgot password
  const [isLogin, setIsLogin] = useState(true);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  // focus states
  const [isNameFocused, setIsNameFocused] = useState(false);
  const [isEmailFocused, setIsEmailFocused] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  // controlled inputs
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  // did user signup or initiate password recovery
  const [didUserSignup, setDidUserSignup] = useState(false);
  const [didUserInitiatePasswordRecovery, setDidUserInitiatePasswordRecovery] =
    useState(false);
  // refs
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    dispatch(resetAuthErrorMsg());
    setDidUserSignup(false);
    setDidUserInitiatePasswordRecovery(false);
  }, [dispatch, isForgotPassword, isLogin]);

  /**
   * Handle on submit
   */
  const handleOnSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    formRef.current?.querySelectorAll('input').forEach(input => input.blur());
    dispatch(resetAuthErrorMsg());

    if (isForgotPassword) {
      dispatch(initiatePasswordRecovery(email)).then(action => {
        if (initiatePasswordRecovery.fulfilled.match(action)) {
          setEmail('');
          setDidUserInitiatePasswordRecovery(true);
        }
      });
    } else if (isLogin) {
      dispatch(login({ email, password }));
    } else {
      dispatch(signup({ name, email, password })).then(action => {
        if (signup.fulfilled.match(action)) {
          setName('');
          setEmail('');
          setPassword('');
          setDidUserSignup(true);
        }
      });
    }
  };

  const isRejected =
    (isSignupRejected ||
      isLoginRejected ||
      isInitiatePasswordRecoveryRejected) &&
    authErrorMsg;
  const isPending =
    isSignupPending || isLoginPending || isInitiatePasswordRecoveryPending;

  return (
    <>
      <h2 className='mb-8 text-center'>
        {isForgotPassword ? 'Reset Password' : isLogin ? 'Login' : 'Signup'}
      </h2>

      {/* Error message */}
      {isRejected && <AuthError message={authErrorMsg} />}

      {/* Success message */}
      {(didUserSignup || didUserInitiatePasswordRecovery) && (
        <p
          role='alert'
          className='mb-6 rounded-lg border-2 border-green-500 bg-green-900 p-2 text-center xs:p-4'
        >
          Email sent with instructions!
        </p>
      )}

      <form onSubmit={handleOnSubmit} className='mb-4' ref={formRef}>
        {/* Full name */}
        {!isLogin && (
          <label
            htmlFor='name'
            className={`${INPUTS.LABEL_EL} mb-4 ${isPending ? 'cursor-not-allowed' : 'cursor-text'}`}
          >
            <span
              className={`${INPUTS.LABEL_SPAN} ${name || isNameFocused ? 'top-0' : 'top-1/2'}`}
            >
              Full Name
            </span>
            <input
              type='text'
              id='name'
              name='name'
              autoComplete='name'
              value={name}
              disabled={isSignupPending}
              required
              onChange={({ target }) => setName(target.value)}
              className={`${INPUTS.INPUT_EL} ${isPending ? 'cursor-not-allowed' : 'cursor-text'}`}
              onFocus={() => setIsNameFocused(true)}
              onBlur={() => setIsNameFocused(false)}
            />
          </label>
        )}

        {/* Email */}
        <label
          htmlFor='email'
          className={`${INPUTS.LABEL_EL} mb-4 ${isPending ? 'cursor-not-allowed' : 'cursor-text'}`}
        >
          <span
            className={`${INPUTS.LABEL_SPAN} ${email || isEmailFocused ? 'top-0' : 'top-1/2'}`}
          >
            Email
          </span>
          <input
            type='email'
            id='email'
            name='email'
            autoComplete='email'
            value={email}
            disabled={isPending}
            required
            onChange={({ target }) => setEmail(target.value)}
            className={`${INPUTS.INPUT_EL} ${isPending ? 'cursor-not-allowed' : 'cursor-text'}`}
            onFocus={() => setIsEmailFocused(true)}
            onBlur={() => setIsEmailFocused(false)}
          />
        </label>

        {/* Password */}
        {!isForgotPassword && (
          <label
            htmlFor='password'
            className={cn(
              `${INPUTS.LABEL_EL} ${isPending ? 'cursor-not-allowed' : 'cursor-text'}`,
              !isLogin && 'mb-4',
            )}
          >
            <span
              className={`${INPUTS.LABEL_SPAN} ${password || isPasswordFocused ? 'top-0' : 'top-1/2'}`}
            >
              Password
            </span>
            <input
              type='password'
              id='password'
              name='password'
              autoComplete={isLogin ? 'current-password' : 'new-password'}
              value={password}
              disabled={isPending}
              required
              onChange={({ target }) => setPassword(target.value)}
              className={`${INPUTS.INPUT_EL} ${isPending ? 'cursor-not-allowed' : 'cursor-text'}`}
              onFocus={() => setIsPasswordFocused(true)}
              onBlur={() => setIsPasswordFocused(false)}
            />
          </label>
        )}

        {/*Forgot password button */}
        {isLogin && !isForgotPassword && (
          <button
            type='button'
            className={cn(
              'mx-auto mb-4 block p-2 text-sm text-yellow-500',
              isPending && 'cursor-not-allowed',
            )}
            onClick={() => setIsForgotPassword(true)}
            disabled={isPending}
          >
            Forgot password
          </button>
        )}

        {/* Submit */}
        <button
          type='submit'
          disabled={isPending}
          className={cn(CTA_PRIMARY, isPending && 'cursor-not-allowed')}
        >
          {isPending && <ButtonSpinner />}
          {isForgotPassword ? 'Reset Password' : isLogin ? 'Login' : 'Signup'}
        </button>
      </form>

      {/* Signup/Login Toggle */}
      <button
        className={cn('mx-auto block p-4', isPending && 'cursor-not-allowed')}
        onClick={
          isForgotPassword
            ? () => setIsForgotPassword(false)
            : () => setIsLogin(prev => !prev)
        }
        disabled={isPending}
      >
        <span>Or</span>{' '}
        <span className='font-bold text-yellow-500'>
          {isForgotPassword ? 'Go Back' : isLogin ? 'Signup' : 'Login'}
        </span>
      </button>
    </>
  );
};
export default AuthPage;

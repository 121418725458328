/**
 * ButtonSpinner component
 */
const ButtonSpinner = () => (
  <div
    role='status'
    className='mr-2 h-6 w-6 animate-spin place-self-center rounded-full border-4 border-x-yellow-700 border-b-yellow-700 border-t-yellow-900'
  />
);

export default ButtonSpinner;

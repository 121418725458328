import { Link } from 'react-router-dom';
import { PATHS } from '../../utils/constants';

/**
 * SiteHeading component for `HomePage` and `PasswordRecovery` components
 */
const SiteHeading = () => (
  <div className='mb-4 text-center'>
    <Link to={PATHS.HOME}>
      <h1>
        Brainy
        <br className='xs:hidden' /> Budget
      </h1>
    </Link>
    <p className='text-neutral-400'>Let your budget do the thinking</p>
  </div>
);

export default SiteHeading;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { STATE_STATUS } from '../../utils/constants';
import { handleError } from '../state.utils';
import { healthAPI } from './health.api';

interface HealthState {
  statuses: {
    checkHealth: STATE_STATUS;
  };
  data: {
    isHealthy: null | boolean;
  };
}

const initialState: HealthState = {
  statuses: {
    checkHealth: STATE_STATUS.INITIAL,
  },
  data: {
    isHealthy: null,
  },
};

/**
 * Thunks
 */
export const checkHealth = createAsyncThunk(
  'health/checkHealth',
  async (_, { signal, rejectWithValue }) => {
    try {
      await healthAPI.checkHealth(signal);
    } catch (error) {
      return handleError(error, rejectWithValue);
    }
  },
);

const healthSlice = createSlice({
  name: 'health',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(checkHealth.pending, state => {
        state.statuses.checkHealth = STATE_STATUS.PENDING;
      })
      .addCase(checkHealth.fulfilled, state => {
        state.statuses.checkHealth = STATE_STATUS.FULFILLED;
        state.data.isHealthy = true;
      })
      .addCase(checkHealth.rejected, state => {
        state.statuses.checkHealth = STATE_STATUS.REJECTED;
        state.data.isHealthy = false;
      });
  },
});

/**
 * Reducer
 */
export const healthReducer = healthSlice.reducer;

/**
 * Selectors
 */
export const selectCheckHealthStatus = (state: RootState) =>
  state.health.statuses.checkHealth;

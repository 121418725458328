import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
  LOCAL_STORAGE,
  STATE_STATUS,
  USER_AUTH_TOKEN_RENEWAL_BUFFER,
} from '../../utils/constants';
import { handleError } from '../state.utils';
import retryRequest from '../../utils/retryRequest';
import {
  AuthErrorRes,
  AuthState,
  LoginPayload,
  RecoverPasswordPayload,
  SignupPayload,
  UserAuthToken,
} from './auth.types';
import { authAPI } from './auth.api';

export const initialState: AuthState = {
  statuses: {
    signup: STATE_STATUS.INITIAL,
    verifySignup: STATE_STATUS.INITIAL,
    login: STATE_STATUS.INITIAL,
    getUserIdentity: STATE_STATUS.INITIAL,
    initiatePasswordRecovery: STATE_STATUS.INITIAL,
    recoverPassword: STATE_STATUS.INITIAL,
    renewToken: STATE_STATUS.INITIAL,
    logout: STATE_STATUS.INITIAL,
  },
  data: {
    userAuthToken: null,
    userIdentity: null,
    errorMsg: '',
  },
};

/**
 * Thunks
 */
// NOTE: handles error message in `HomePage`
export const signup = createAsyncThunk(
  'auth/signup',
  async (payload: SignupPayload, { signal, rejectWithValue }) => {
    try {
      await authAPI.signup(payload, signal);
    } catch (error) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const verifySignup = createAsyncThunk(
  'auth/verifySignup',
  async (token: string, { signal, rejectWithValue }) => {
    try {
      const { data } = await authAPI.verifySignup(token, signal);
      return data;
    } catch (error) {
      return handleError(error, rejectWithValue);
    }
  },
);

// NOTE: handles error message in `HomePage`
export const login = createAsyncThunk(
  'auth/login',
  async (payload: LoginPayload, { signal, rejectWithValue }) => {
    try {
      const { data } = await authAPI.login(payload, signal);
      return data;
    } catch (error) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const getUserIdentity = createAsyncThunk(
  'auth/getUserIdentity',
  async (_, { signal, rejectWithValue }) => {
    try {
      const { data } = await authAPI.getUserIdentity(signal);
      return data;
    } catch (error) {
      return handleError(error, rejectWithValue);
    }
  },
);

// NOTE: handles error message in `HomePage`
export const initiatePasswordRecovery = createAsyncThunk(
  'auth/initiatePasswordRecovery',
  async (email: string, { signal, rejectWithValue }) => {
    try {
      await authAPI.initiatePasswordRecovery(email, signal);
    } catch (error) {
      return handleError(error, rejectWithValue);
    }
  },
);

// NOTE: handles error message in `PasswordRecovery`
export const recoverPassword = createAsyncThunk(
  'auth/recoverPassword',
  async (payload: RecoverPasswordPayload, { signal, rejectWithValue }) => {
    try {
      const { data } = await authAPI.recoverPassword(payload, signal);
      return data;
    } catch (error) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const renewToken = createAsyncThunk(
  'auth/renewToken',
  async (refreshToken: string, { signal, rejectWithValue, dispatch }) => {
    return retryRequest<ReturnType<typeof handleError>, UserAuthToken>(
      () => authAPI.renewToken(refreshToken, signal),
      (error: unknown) => {
        setTimeout(() => dispatch(logout()), 0); // push to end of cb queue
        return handleError(error, rejectWithValue);
      },
    );
  },
);

export const logout = createAsyncThunk(
  'auth/logout',
  async (_, { signal, rejectWithValue }) => {
    try {
      await authAPI.logout(signal);
    } catch (error) {
      return handleError(error, rejectWithValue);
    }
  },
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    /**
     * Reset error message
     */
    resetAuthErrorMsg(state) {
      state.data.errorMsg = '';
    },
    /**
     * Set user auth token
     */
    setUserAuthToken(state, action: PayloadAction<UserAuthToken>) {
      state.data.userAuthToken = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      // signup
      .addCase(signup.pending, state => {
        state.statuses.signup = STATE_STATUS.PENDING;
      })
      .addCase(signup.fulfilled, state => {
        state.statuses.signup = STATE_STATUS.FULFILLED;
      })
      .addCase(
        signup.rejected.type,
        (state, action: PayloadAction<AuthErrorRes>) => {
          state.data.errorMsg = action.payload.message;
          state.statuses.signup = STATE_STATUS.REJECTED;
        },
      )
      // verifySignup
      .addCase(verifySignup.pending, state => {
        state.statuses.verifySignup = STATE_STATUS.PENDING;
      })
      .addCase(verifySignup.fulfilled, (state, action) => {
        state.data.userAuthToken = getUserAuthTokenAndSetLocalStorage({
          ...action.payload,
        });
        state.statuses.verifySignup = STATE_STATUS.FULFILLED;
      })
      .addCase(verifySignup.rejected, state => {
        state.statuses.verifySignup = STATE_STATUS.REJECTED;
      })
      // login
      .addCase(login.pending, state => {
        state.statuses.login = STATE_STATUS.PENDING;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.data.userAuthToken = getUserAuthTokenAndSetLocalStorage({
          ...action.payload,
        });
        state.statuses.login = STATE_STATUS.FULFILLED;
      })
      .addCase(
        login.rejected.type,
        (state, action: PayloadAction<AuthErrorRes>) => {
          state.data.errorMsg = action.payload.message;
          state.statuses.login = STATE_STATUS.REJECTED;
        },
      )
      // getUserIdentity
      .addCase(getUserIdentity.pending, state => {
        state.statuses.getUserIdentity = STATE_STATUS.PENDING;
      })
      .addCase(getUserIdentity.fulfilled, (state, action) => {
        state.data.userIdentity = action.payload;
        state.statuses.getUserIdentity = STATE_STATUS.FULFILLED;
      })
      .addCase(getUserIdentity.rejected, state => {
        state.statuses.getUserIdentity = STATE_STATUS.REJECTED;
      })
      // initiatePasswordRecovery
      .addCase(initiatePasswordRecovery.pending, state => {
        state.statuses.initiatePasswordRecovery = STATE_STATUS.PENDING;
      })
      .addCase(initiatePasswordRecovery.fulfilled, state => {
        state.statuses.initiatePasswordRecovery = STATE_STATUS.FULFILLED;
      })
      .addCase(
        initiatePasswordRecovery.rejected.type,
        (state, action: PayloadAction<AuthErrorRes>) => {
          state.data.errorMsg = action.payload.message;
          state.statuses.initiatePasswordRecovery = STATE_STATUS.REJECTED;
        },
      )
      // recoverPassword
      .addCase(recoverPassword.pending, state => {
        state.statuses.recoverPassword = STATE_STATUS.PENDING;
      })
      .addCase(recoverPassword.fulfilled, (state, action) => {
        state.data.userAuthToken = getUserAuthTokenAndSetLocalStorage({
          ...action.payload,
        });

        state.statuses.recoverPassword = STATE_STATUS.FULFILLED;
      })
      .addCase(
        recoverPassword.rejected.type,
        (state, action: PayloadAction<AuthErrorRes>) => {
          state.data.errorMsg = action.payload.message;
          state.statuses.recoverPassword = STATE_STATUS.REJECTED;
        },
      )
      // renewToken
      .addCase(renewToken.pending, state => {
        state.statuses.renewToken = STATE_STATUS.PENDING;
      })
      .addCase(renewToken.fulfilled, (state, action) => {
        state.data.userAuthToken = getUserAuthTokenAndSetLocalStorage({
          ...action.payload,
        });

        state.statuses.renewToken = STATE_STATUS.FULFILLED;
      })
      .addCase(renewToken.rejected, state => {
        state.statuses.renewToken = STATE_STATUS.REJECTED;
        localStorage.removeItem(LOCAL_STORAGE.TOKEN);
      })
      // logout
      .addCase(logout.pending, state => {
        state.statuses.logout = STATE_STATUS.PENDING;
      })
      .addCase(logout.fulfilled, state => {
        state.statuses.logout = STATE_STATUS.FULFILLED;
        localStorage.removeItem(LOCAL_STORAGE.TOKEN);
      })
      .addCase(logout.rejected, state => {
        state.statuses.logout = STATE_STATUS.REJECTED;
      });
  },
});

/**
 * Helpers
 */
const getUserAuthTokenAndSetLocalStorage = (payload: UserAuthToken) => {
  // 5 minutes before expiry
  const expiresInMS =
    (payload.expires_in - USER_AUTH_TOKEN_RENEWAL_BUFFER) * 1000;

  payload.expires_at = Date.now() + expiresInMS;
  localStorage.setItem(LOCAL_STORAGE.TOKEN, JSON.stringify(payload));

  return payload;
};

/**
 * Selectors
 */
// userAuthToken
export const selectHasUserAuthToken = (state: RootState) =>
  !!state.auth.data.userAuthToken;
// userIdentity
export const selectUserAuthToken = (state: RootState) =>
  state.auth.data.userAuthToken;
export const selectUserIdentity = (state: RootState) =>
  state.auth.data.userIdentity;
export const selectHasUserIdentity = (state: RootState) =>
  !!state.auth.data.userIdentity;
// signup
export const selectIsSignupPending = (state: RootState) =>
  state.auth.statuses.signup === STATE_STATUS.PENDING;
export const selectIsSignupRejected = (state: RootState) =>
  state.auth.statuses.signup === STATE_STATUS.REJECTED;
// verifySignup
export const selectVerifySignupStatus = (state: RootState) =>
  state.auth.statuses.verifySignup;
// login
export const selectIsLoginPending = (state: RootState) =>
  state.auth.statuses.login === STATE_STATUS.PENDING;
export const selectIsLoginRejected = (state: RootState) =>
  state.auth.statuses.login === STATE_STATUS.REJECTED;
// initiatePasswordRecovery
export const selectIsInitiatePasswordRecoveryPending = (state: RootState) =>
  state.auth.statuses.initiatePasswordRecovery === STATE_STATUS.PENDING;
export const selectIsInitiatePasswordRecoveryRejected = (state: RootState) =>
  state.auth.statuses.initiatePasswordRecovery === STATE_STATUS.REJECTED;
// recoverPassword
export const selectIsRecoverPasswordPending = (state: RootState) =>
  state.auth.statuses.recoverPassword === STATE_STATUS.PENDING;
// errorMsg
export const selectAuthErrorMsg = (state: RootState) =>
  state.auth.data.errorMsg;

/**
 * Actions
 */
export const { resetAuthErrorMsg, setUserAuthToken } = authSlice.actions;

/**
 * Reducer
 */
export const authReducer = authSlice.reducer;

import { useSelector } from 'react-redux';
import { PropsWithChildren, useEffect } from 'react';
import { selectGlobalErrorMessage } from '../../state/error/error.slice';
import { useAppDispatch } from '../../hooks/redux';
import { toggleLoading } from '../../state/loading/loading.slice';
import Error from '../Error/Error';

/**
 * Error component for displaying redux error messages
 */
const ErrorView = ({ children }: PropsWithChildren) => {
  const dispatch = useAppDispatch();
  const errorMsg = useSelector(selectGlobalErrorMessage);

  useEffect(() => {
    errorMsg && dispatch(toggleLoading(false));
  }, [dispatch, errorMsg]);

  return errorMsg ? <Error message={errorMsg} /> : <>{children}</>;
};
export default ErrorView;

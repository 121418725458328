import { Link } from 'react-router-dom';
import { PATHS } from '../../utils/constants';

export interface LogoProps {
  role: 'progressbar' | 'banner';
}

/**
 * Logo
 */
const Logo = ({ role }: LogoProps) => {
  const logo = '✨';
  const logoCNs = 'block animate-pulse text-center text-9xl';
  const logoJSX =
    role === 'progressbar' ? (
      <div className={logoCNs}>{logo}</div>
    ) : (
      <Link to={PATHS.HOME} className={logoCNs}>
        {logo}
      </Link>
    );

  return (
    <div
      className='relative mx-auto mb-4'
      role={role}
      id='logo'
      {...(role === 'progressbar' && { 'aria-label': 'Loading' })}
    >
      {logoJSX}
    </div>
  );
};

export default Logo;

import Image from '../../components/Image/Image';
import Video from '../../components/Video/Video';

/**
 * AboutPage
 */
const AboutPage = () => {
  return (
    <div className='text-lg'>
      <h2 className='text-center'>About</h2>

      <Video
        src='https://www.youtube.com/embed/j4s8D9hjFQQ'
        classNames='mb-8'
      />

      <p>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Qui, quidem?
        Obcaecati quasi aperiam, ipsum aliquid magni debitis mollitia sunt
        officiis deleniti cupiditate ipsam officia quia. Corrupti itaque soluta
        iure excepturi?
      </p>
      <p>
        Fuga aliquam fugiat totam nulla consectetur maxime consequuntur nihil
        vel ratione neque atque delectus temporibus, voluptatem vitae earum
        provident possimus repellat omnis dicta quam non! Nam illum repudiandae
        debitis ipsam?
      </p>
      <p className='mb-8'>
        Vel facere ea eveniet officia modi rem. Doloremque aut eius, perferendis
        at harum architecto commodi eveniet quisquam itaque hic ad magnam, qui
        iste error. Excepturi necessitatibus ipsum veniam ab ullam.
      </p>

      <Image src='/images/about/me.jpg' alt='' />
    </div>
  );
};

export default AboutPage;

import { Link } from 'react-router-dom';
import { QueryParamKey, TransactionsTab } from '../../utils/constants';

const to = `/app/transactions?${QueryParamKey.TransactionsTab}=`;

interface TransactionsTabsProps {
  searchParam: TransactionsTab | null;
}

/**
 * Top transactions tabs on transactions page
 */
const TransactionsTabs = ({ searchParam }: TransactionsTabsProps) => {
  /**
   * Check if the tab is active
   */
  const isActive = (tab: string) => {
    return tab === searchParam
      ? 'border-b-4 border-blue-500'
      : 'text-neutral-400 border-b border-neutral-700';
  };

  return (
    <div className='grid grid-cols-2 text-center'>
      <Link
        to={`${to}${TransactionsTab.Daily}`}
        className={`pb-2 ${isActive(TransactionsTab.Daily)}`}
      >
        {TransactionsTab.Daily}
      </Link>
      <Link
        to={`${to}${TransactionsTab.Summary}`}
        className={`pb-2 ${isActive(TransactionsTab.Summary)}`}
      >
        {TransactionsTab.Summary}
      </Link>
    </div>
  );
};

export default TransactionsTabs;

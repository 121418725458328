import { useEffect } from 'react';
import { selectUserAuthToken, renewToken } from '../state/auth/auth.slice';
import { useAppDispatch, useAppSelector } from './redux';

/**
 * Hook that handles document visibility change
 */
const useRenewUserAuthTokenInterval = () => {
  const dispatch = useAppDispatch();
  const userAuthToken = useAppSelector(selectUserAuthToken);

  useEffect(() => {
    if (!userAuthToken) return;

    /**
     * Registers an interval to renew the user's auth token
     */
    const registerInterval = () => {
      const intervalDuration = Math.max(
        userAuthToken.expires_at - Date.now(),
        0,
      );
      return setInterval(() => {
        dispatch(renewToken(userAuthToken.refresh_token));
      }, intervalDuration);
    };

    let intervalId = registerInterval();

    /**
     * Handles document visibility change
     */
    const handleDocumentVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        if (userAuthToken.expires_at <= Date.now()) {
          // NOTE: this will trigger the `useEffect` again
          // and register a new interval, because cleared during cleanup
          dispatch(renewToken(userAuthToken.refresh_token));
        } else {
          // clear existing interval and reregister interval
          clearInterval(intervalId);
          intervalId = registerInterval();
        }
      } else {
        clearInterval(intervalId);
      }
    };

    document.addEventListener(
      'visibilitychange',
      handleDocumentVisibilityChange,
    );

    return () => {
      clearInterval(intervalId);
      document.removeEventListener(
        'visibilitychange',
        handleDocumentVisibilityChange,
      );
    };
  }, [dispatch, userAuthToken]);
};
export default useRenewUserAuthTokenInterval;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { login, logout, recoverPassword } from '../auth/auth.slice';

interface LoadingState {
  data: {
    isLoading: boolean;
  };
}

const initialState: LoadingState = {
  data: {
    isLoading: true,
  },
};

const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    toggleLoading: (state, action: PayloadAction<boolean>) => {
      state.data.isLoading = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      // login
      .addCase(login.fulfilled, state => {
        state.data.isLoading = true;
      })
      .addCase(recoverPassword.fulfilled, state => {
        state.data.isLoading = true;
      })
      // logout
      .addCase(logout.pending, state => {
        state.data.isLoading = true;
      })
      .addCase(logout.rejected, state => {
        state.data.isLoading = false;
      });
  },
});

/**
 * Reducer
 */
export const loadingReducer = loadingSlice.reducer;

/**
 * Actions
 */
export const { toggleLoading } = loadingSlice.actions;

/**
 * Selectors
 */
export const selectIsLoading = (state: RootState) =>
  state.loading.data.isLoading;

import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../hooks/redux';
import { logout } from '../../../state/auth/auth.slice';
import { PATHS } from '../../../utils/constants';

/**
 * SettingsPage
 */
const SettingsPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <div>
      <h1>SettingsPage</h1>
      <button
        onClick={() =>
          dispatch(logout()).then(action => {
            if (logout.fulfilled.match(action)) navigate(PATHS.HOME);
          })
        }
      >
        Logout
      </button>
    </div>
  );
};
export default SettingsPage;

import React, { lazy, FC, PropsWithChildren } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from './state/store';
import reportWebVitals from './reportWebVitals';
import Analytics from './components/Analytics/Analytics';
import Layout from './components/Layout/Layout';
import Routes from './components/Routes/Routes';

import './index.css';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import Oops from './components/Oops/Oops';
import PostAppInit from './components/PostAppInit/PostAppInit';
import Loader from './components/Loader/Loader';
import ErrorView from './components/ErrorView/ErrorView';
import AppInit from './components/AppInit/AppInit';

const container = document.getElementById('root')!;
const root = createRoot(container);

/**
 * Render the app with the given Analytics component.
 */
const render = (Analytics: FC<PropsWithChildren>) =>
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <ErrorBoundary fallbackUI={Oops}>
          <Analytics>
            <Provider store={store}>
              <ErrorView>
                <AppInit>
                  <PostAppInit>
                    <Layout>
                      <Routes />
                    </Layout>
                  </PostAppInit>
                </AppInit>
              </ErrorView>
              <Loader fullScreen />
            </Provider>
          </Analytics>
        </ErrorBoundary>
      </BrowserRouter>
    </React.StrictMode>,
  );

if (process.env.NODE_ENV === 'production') {
  render(Analytics);
} else {
  const LocalAnalytics = lazy(
    () => import('./components/LocalAnalytics/LocalAnalytics'),
  );
  render(LocalAnalytics);

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals(console.log);
}

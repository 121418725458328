import { PropsWithChildren, useEffect } from 'react';
import { LOCAL_STORAGE } from '../../utils/constants';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import {
  selectHasUserAuthToken,
  setUserAuthToken,
} from '../../state/auth/auth.slice';
import { UserAuthToken } from '../../state/auth/auth.types';

/**
 * LocalStorage component
 */
const LocalStorage = ({ children }: PropsWithChildren) => {
  const dispatch = useAppDispatch();
  const hasUserAuthToken = useAppSelector(selectHasUserAuthToken);

  // parse token from local storage, if exists
  let localStorageVal = localStorage.getItem(LOCAL_STORAGE.TOKEN);
  let localStorageToken: UserAuthToken | null = null;
  if (localStorageVal) {
    try {
      localStorageToken = JSON.parse(localStorageVal);
    } catch (error) {
      localStorage.removeItem(LOCAL_STORAGE.TOKEN);
    }
  }

  useEffect(() => {
    if (localStorageToken) {
      dispatch(setUserAuthToken(localStorageToken));
    }
  }, [dispatch, localStorageToken]);

  if (localStorageToken) {
    return hasUserAuthToken ? <>{children}</> : null;
  }

  return <>{children}</>;
};

export default LocalStorage;

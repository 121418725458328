import { PropsWithChildren } from 'react';
import HealthCheck from '../HealthCheck/HealthCheck';
import LocalStorage from '../LocalStorage/LocalStorage';
import PasswordRecovery from '../PasswordRecovery/PasswordRecovery';
import SignupVerification from '../SignupVerification/SignupVerification';
import UserIdentityAndData from '../UserIdentityAndData/UserIdentityAndData';
import TokenRenewal from '../TokenRenewal/TokenRenewal';

/**
 * AppInit component
 */
const AppInit = ({ children }: PropsWithChildren) => (
  <HealthCheck>
    <SignupVerification>
      <PasswordRecovery>
        <LocalStorage>
          <TokenRenewal>
            <UserIdentityAndData>{children}</UserIdentityAndData>
          </TokenRenewal>
        </LocalStorage>
      </PasswordRecovery>
    </SignupVerification>
  </HealthCheck>
);

export default AppInit;

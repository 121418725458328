import Video from '../../components/Video/Video';

/**
 * TutorialsPage
 */
const TutorialsPage = () => {
  return (
    <div className='text-center text-lg'>
      <h2 className='mb-8'>Tutorials</h2>

      <h3>Quick Start</h3>
      <Video
        src='https://www.youtube.com/embed/u31qwQUeGuM'
        classNames='mb-8'
      />

      <h3>How to Add Virtual Accounts</h3>
      <Video
        src='https://www.youtube.com/embed/eEzD-Y97ges'
        classNames='mb-8'
      />

      <h3>How to Create A Budget</h3>
      <Video
        src='https://www.youtube.com/embed/4vhb7aHjuFk'
        classNames='mb-8'
      />

      <h3>How to Create Transactions</h3>
      <Video
        src='https://www.youtube.com/embed/pygGug3TCaI'
        classNames='mb-8'
      />

      <h3>How to Create Profiles</h3>
      <Video
        src='https://www.youtube.com/embed/RwU7YY6emYc'
        classNames='mb-8'
      />

      <h3>How to Use the AI</h3>
      <Video src='https://www.youtube.com/embed/Jdf6BzzfOqM' />
    </div>
  );
};

export default TutorialsPage;

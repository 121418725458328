import { apiHTTP } from '../../http/http';
import { User } from './user.types';

/**
 * Get user data
 */
const getUser = (signal: AbortSignal) =>
  apiHTTP.get<User>('/api/users/me', { signal });

const userAPI = {
  getUser,
};

export default userAPI;

/* eslint-disable jsx-a11y/media-has-caption */
import cn from 'classnames';

const baseCNs = 'aspect-video w-full rounded-2xl bg-neutral-700';

interface VideoProps {
  src: string;
  classNames?: string;
}

/**
 * Video component
 */
const Video = ({ src, classNames }: VideoProps) => {
  return src.startsWith('/') ? (
    <video
      className={cn(baseCNs, classNames)}
      src={src}
      controls
      preload='metadata'
    >
      Your browser does not support the video tag.
    </video>
  ) : (
    <iframe
      loading='lazy'
      className={cn(baseCNs, classNames)}
      src={src}
      title='Brainy Budget video'
      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
      referrerPolicy='strict-origin-when-cross-origin'
      allowFullScreen
    ></iframe>
  );
};

export default Video;

import { PropsWithChildren } from 'react';
import { Toaster } from 'react-hot-toast';
import { toastOptions } from '../../utils/constants';
import { useAppSelector } from '../../hooks/redux';
import { selectHasUserIdentity } from '../../state/auth/auth.slice';
import Logo from '../Logo/Logo';
import SiteHeading from '../SiteHeading/SiteHeading';
import Navigation from '../Navigation/Navigation';

interface LayoutProps extends PropsWithChildren {
  showNav?: boolean;
}

/**
 * Layout component
 */
const Layout = ({ children, showNav = true }: LayoutProps) => {
  const hasUserIdentity = useAppSelector(selectHasUserIdentity);

  if (hasUserIdentity) {
    return (
      <main>
        {children}
        <Toaster toastOptions={toastOptions} />
      </main>
    );
  }

  return (
    <div className='not-authed min-h-dvh p-4 xs:p-8'>
      <div className='mx-auto max-w-screen-sm'>
        <Logo role='banner' />
        <main>
          <SiteHeading />
          {showNav && <Navigation />}

          {children}

          <Toaster toastOptions={toastOptions} />
        </main>
      </div>
    </div>
  );
};

export default Layout;

import { identityHTTP } from '../../http/http';
import {
  LoginPayload,
  RecoverPasswordPayload,
  SignupPayload,
  UserIdentity,
  UserAuthToken,
} from './auth.types';

/**
 * Signup a new user
 */
const signup = (
  { email, password, name }: SignupPayload,
  signal: AbortSignal,
) =>
  identityHTTP.post(
    '/signup',
    {
      email,
      password,
      data: {
        full_name: name,
      },
    },
    {
      signal,
    },
  );

/**
 * Verify signup
 */
const verifySignup = (token: string, signal: AbortSignal) =>
  identityHTTP.post<UserAuthToken>(
    '/verify',
    {
      type: 'signup',
      token,
    },
    {
      signal,
    },
  );

/**
 * Login
 */
const login = ({ email, password }: LoginPayload, signal: AbortSignal) =>
  identityHTTP.postForm<UserAuthToken>(
    '/token',
    { grant_type: 'password', username: email, password },
    {
      signal,
    },
  );

/**
 * Get user
 */
const getUserIdentity = (signal: AbortSignal) =>
  identityHTTP.get<UserIdentity>('/user', { signal });

/**
 * Initiate password recovery
 */
const initiatePasswordRecovery = (email: string, signal: AbortSignal) =>
  identityHTTP.post<{}>('/recover', { email }, { signal });

/**
 * Recover password
 */
const recoverPassword = (
  { token, password }: RecoverPasswordPayload,
  signal: AbortSignal,
) =>
  identityHTTP.post<UserAuthToken>(
    '/verify',
    {
      type: 'recovery',
      token,
      password,
    },
    {
      signal,
    },
  );

/**
 * Renew token
 */
const renewToken = (refreshToken: string, signal: AbortSignal) =>
  identityHTTP.postForm<UserAuthToken>(
    '/token',
    {
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
    },
    {
      signal,
    },
  );

/**
 * Logout
 */
const logout = (signal: AbortSignal) =>
  identityHTTP.post('/logout', null, { signal });

export const authAPI = {
  signup,
  verifySignup,
  login,
  getUserIdentity,
  initiatePasswordRecovery,
  recoverPassword,
  renewToken,
  logout,
};

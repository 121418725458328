import Error from '../Error/Error';
import { FallbackUIProps } from '../ErrorBoundary/ErrorBoundary';

/**
 * Oops component
 * @description This component is used as a fallback UI in the ErrorBoundary component.
 */
const Oops = ({ errorMessage }: FallbackUIProps) => (
  <Error message={errorMessage} />
);

export default Oops;

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../hooks/redux';
import { selectHasUserIdentity } from '../../state/auth/auth.slice';

interface RedirectIfNotAuthedProps {
  AuthedComponent: React.FC;
  notAuthedPath: string;
}

/**
 * Redirects to the specified path if the user is not authenticated.
 */
const RedirectIfNotAuthed = ({
  AuthedComponent,
  notAuthedPath,
}: RedirectIfNotAuthedProps) => {
  const hasUserIdentity = useAppSelector(selectHasUserIdentity);
  const navigate = useNavigate();

  useEffect(() => {
    if (!hasUserIdentity) {
      navigate(notAuthedPath);
    }
  }, [hasUserIdentity, notAuthedPath, navigate]);

  return hasUserIdentity ? <AuthedComponent /> : null;
};

export default RedirectIfNotAuthed;

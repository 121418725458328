import {
  Action,
  AnyAction,
  combineReducers,
  configureStore,
  ThunkAction,
} from '@reduxjs/toolkit';
// import { isProd } from '../utils/isEnv';
import { authReducer, logout } from './auth/auth.slice';
import { healthReducer } from './health/health.slice';
import { errorReducer } from './error/error.slice';
import { loadingReducer } from './loading/loading.slice';
import { userReducer } from './user/user.slice';

export const appReducer = combineReducers({
  loading: loadingReducer,
  error: errorReducer,
  health: healthReducer,
  auth: authReducer,
  user: userReducer,
});

/**
 * Root reducer
 */
export const rootReducer = (
  state: ReturnType<typeof appReducer> | undefined,
  action: AnyAction,
) => {
  if (action.type === logout.fulfilled.type) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  // devTools: !isProd(),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export type AppStore = typeof store;

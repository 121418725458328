import { DefaultToastOptions } from 'react-hot-toast';
import theme from '../theme';

/**
 * Toast
 */
export const toastOptions: DefaultToastOptions = {
  success: {
    icon: '✅',
    style: {
      backgroundColor: theme.colors.green[900],
      borderWidth: theme.borderWidth[2],
      borderColor: theme.colors.green[500],
      color: theme.colors.neutral[300],
    },
  },
  error: {
    icon: '❌',
    style: {
      backgroundColor: theme.colors.red[950],
      borderWidth: theme.borderWidth[2],
      borderColor: theme.colors.red[600],
      color: theme.colors.neutral[300],
    },
  },
};

/**
 * URL
 */
export const PATHS = {
  HOME: '/',
  AUTH: '/auth',
  TUTORIALS: '/tutorials',
  ABOUT: '/about',
  APP: '/app',
  TRANSACTIONS: '/app/transactions',
};

export enum HashKey {
  ConfirmationToken = '#confirmation_token',
  RecoveryToken = '#recovery_token',
}

export enum QueryParamKey {
  TransactionsMonth = 'transactionsMonth',
  TransactionsTab = 'transactionsTab',
}

export enum TransactionsTab {
  Daily = 'Daily',
  Summary = 'Summary',
}

export const REPLACE = { replace: true };

/**
 * HTTP statuses
 */
export enum STATE_STATUS {
  INITIAL = 'initial',
  PENDING = 'pending',
  FULFILLED = 'fulfilled',
  REJECTED = 'rejected',
}

/**
 * Error messages
 */
export const ERROR_MSGS = {
  GENERIC: 'An error occurred',
};

/**
 * TIME
 */
// Request retries
export const REQUEST_RETRIES = 3;
export const REQUEST_RETRY_DELAY = 60_000;
export const USER_AUTH_TOKEN_RENEWAL_BUFFER = 300;

/**
 * Local storage
 */
export const LOCAL_STORAGE = {
  TOKEN: 'token',
};

/**
 * Links and Buttons
 */
export const CTA_PRIMARY =
  'grid place-items-center block h-[68px] w-full rounded-full border-4 border-yellow-600 bg-yellow-600 text-lg font-bold text-neutral-800';

/**
 * Inputs
 */
export const INPUTS = {
  LABEL_EL: 'relative block',
  LABEL_SPAN:
    'absolute left-6 -translate-y-1/2 rounded-lg bg-neutral-800 px-2 text-lg text-neutral-400 transition-all',
  INPUT_EL:
    'block w-full rounded-full border-4 border-yellow-600 bg-neutral-800 px-6 py-4 text-lg',
  BTN_PRIMARY:
    'flex h-[68px] w-full items-center justify-center rounded-full border-4 border-yellow-600 bg-yellow-600 px-2 text-lg font-bold text-neutral-800',
};

interface ErrorProps {
  message: string;
}

/**
 * Error component
 * @description Used in `ErrorView` and `Oops` components
 */
const Error = ({ message }: ErrorProps) => (
  <main className='mx-auto max-w-screen-sm p-4 text-center xs:p-8'>
    <h1 className='mb-2 text-3xl'>Oops!</h1>
    <h2 className='mb-4 text-xl'>Something went wrong.</h2>
    <code className='block w-full bg-red-950 p-4'>{message}</code>
  </main>
);

export default Error;

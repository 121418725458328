import {
  FormEvent,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  selectIsRecoverPasswordPending,
  recoverPassword,
  selectAuthErrorMsg,
  resetAuthErrorMsg,
} from '../../state/auth/auth.slice';
import { HashKey, PATHS, INPUTS, CTA_PRIMARY } from '../../utils/constants';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { toggleLoading } from '../../state/loading/loading.slice';
import AuthError from '../AuthError/AuthError';
import ButtonSpinner from '../ButtonSpinner/ButtonSpinner';
import Layout from '../Layout/Layout';

/**
 * PasswordRecovery component
 */
const PasswordRecovery = ({ children }: PropsWithChildren) => {
  const dispatch = useAppDispatch();
  // selectors
  const isRecoverPasswordPending = useAppSelector(
    selectIsRecoverPasswordPending,
  );
  const authErrorMsg = useAppSelector(selectAuthErrorMsg);
  // router
  const location = useLocation();
  const navigate = useNavigate();
  // state
  const [hash, setHash] = useState(location.hash);
  const [isPasswordRecovery, setIsPasswordRecovery] = useState(
    hash.startsWith(HashKey.RecoveryToken),
  );
  const [password, setPassword] = useState('');
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  // refs
  const formRef = useRef<HTMLFormElement>(null);

  /**
   * Swallow token from hash
   */
  useEffect(() => {
    if (isPasswordRecovery) {
      navigate(PATHS.HOME, { replace: true });
      dispatch(toggleLoading(false));
    }
  }, [dispatch, navigate, isPasswordRecovery]);

  // short circuit if not recovering password
  if (!isPasswordRecovery) return <>{children}</>;

  /**
   * Submit new password
   */
  const handleOnSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    formRef.current?.querySelectorAll('input').forEach(input => input.blur());
    dispatch(resetAuthErrorMsg());
    dispatch(recoverPassword({ password, token: hash.split('=')[1] })).then(
      action => {
        if (recoverPassword.fulfilled.match(action)) {
          setHash('');
          setIsPasswordRecovery(false);
        }
      },
    );
  };

  return (
    <Layout showNav={false}>
      <div className='h-4' />
      <h2 className='mb-8 text-center'>Enter a New Password</h2>
      {/* Error message */}
      {authErrorMsg && <AuthError message={authErrorMsg} />}

      <form onSubmit={handleOnSubmit} className='mb-4' ref={formRef}>
        {/* Password */}
        <label
          htmlFor='password'
          className={`${INPUTS.LABEL_EL} mb-4 ${isRecoverPasswordPending ? 'cursor-not-allowed' : 'cursor-text'}`}
        >
          <span
            className={`${INPUTS.LABEL_SPAN} ${password || isPasswordFocused ? 'top-0' : 'top-1/2'}`}
          >
            New Password
          </span>
          <input
            type='password'
            id='password'
            name='password'
            autoComplete='new-password'
            value={password}
            disabled={isRecoverPasswordPending}
            required
            onChange={({ target }) => setPassword(target.value)}
            className={`${INPUTS.INPUT_EL} ${isRecoverPasswordPending ? 'cursor-not-allowed' : 'cursor-text'}`}
            onFocus={() => setIsPasswordFocused(true)}
            onBlur={() => setIsPasswordFocused(false)}
          />
        </label>

        {/* Submit */}
        <button
          type='submit'
          disabled={isRecoverPasswordPending}
          className={cn(
            CTA_PRIMARY,
            isRecoverPasswordPending && 'cursor-not-allowed',
          )}
        >
          {isRecoverPasswordPending && <ButtonSpinner />}
          Submit
        </button>
      </form>
    </Layout>
  );
};

export default PasswordRecovery;

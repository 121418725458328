import { PropsWithChildren, useEffect } from 'react';
import { useAppDispatch } from '../../hooks/redux';
import { toggleLoading } from '../../state/loading/loading.slice';

/**
 * PostAppInit component
 */
const PostAppInit = ({ children }: PropsWithChildren) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(toggleLoading(false));
  }, [dispatch]);

  return <>{children}</>;
};

export default PostAppInit;

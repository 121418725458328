import { AxiosResponse } from 'axios';
import { REQUEST_RETRIES, REQUEST_RETRY_DELAY } from './constants';

/**
 * Retries a request if it fails
 */
const retryRequest = <E, S>(
  request: () => Promise<AxiosResponse<S>>,
  onFail: (error: unknown) => E,
): Promise<E | S> => {
  let attempt = 0;

  /**
   * Attempt to make the request
   */
  const attemptRequest = async (): Promise<E | S> => {
    attempt++;
    try {
      const { data } = await request();
      return data;
    } catch (error) {
      if (attempt < REQUEST_RETRIES) {
        await new Promise(resolve => setTimeout(resolve, REQUEST_RETRY_DELAY));
        return attemptRequest();
      } else {
        return onFail(error);
      }
    }
  };

  return attemptRequest();
};

export default retryRequest;
